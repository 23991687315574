import axios from "axios";

class LocationService {
  async getUserLocation() {
    const { data } = await axios.get("https://ipv4.seeip.org/jsonip");

    const { data: loc } = await axios.get(`https://ipapi.co/${data.ip}/json/`);

    return { userIP: data.ip, userLocation: `${loc.city}, ${loc.region_code} - ${loc.country_name}` };
  }
}

const locationService = new LocationService();

export { locationService };
