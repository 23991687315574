// Core
import { ref } from "vue";

// Libraries
import { projectStorage } from "../firebase/config";
import { ref as refStorage, uploadBytesResumable, getDownloadURL } from "firebase/storage";

// Services & Helpers
import { handleRuntimeErrors } from "@/helpers/error-handler";

// Composables
import { useUser } from "./useUser";

const { user } = useUser();

const useStorage = () => {
  const error = ref<unknown | null>(null);
  const url = ref<string | null>(user.value?.photoURL ? user.value?.photoURL : null);
  const filePath = ref<string | null>(null);

  const uploadImage = async (file: File, type: string) => {
    if (!user.value) return;

    filePath.value = `${type}/${user.value.uid}/${file.name}`;

    const storageRef = refStorage(projectStorage, filePath.value);

    try {
      const uploadTask = await uploadBytesResumable(storageRef, file);
      url.value = await getDownloadURL(uploadTask.ref);
    } catch (err) {
      handleRuntimeErrors(err);
      error.value = err;
    }
  };

  const deleteImage = async (path: string) => {
    const storageRef = projectStorage.ref(path);

    try {
      await storageRef.delete();
      url.value = null;
    } catch (err) {
      handleRuntimeErrors(err);
      error.value = err;
    }
  };

  const setInStorage = (key: string, value: string): void => {
    try {
      localStorage.setItem(key, value);
    } catch (error) {
      console.error("Erro ao salvar no localStorage:", error);
    }
  };

  const getStoredItem = (key: string): string | null => {
    try {
      return localStorage.getItem(key);
    } catch (error) {
      console.error("Erro ao acessar localStorage:", error);
      return null;
    }
  };

  return { uploadImage, deleteImage, setInStorage, getStoredItem, url, filePath, error };
};

export default useStorage;
