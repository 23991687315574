// Core
import { createApp } from "vue";
import router from "./router";
import App from "./App.vue";

// Libraries
import "bootstrap";
import drag from "v-drag";
import { createPinia } from "pinia";
import Vue3Lottie from "vue3-lottie";
import VueRewards from "vue-rewards";
import * as Sentry from "@sentry/vue";
import ElementPlus from "element-plus";
import PrimeVue from "primevue/config";
import mixpanel from "mixpanel-browser";
import Aura from "@primevue/themes/aura";
import { createHead } from "@vueuse/head";
import VueDragscroll from "vue-dragscroll";
import { MotionPlugin } from "@vueuse/motion";
import MasonryWall from "@yeger/vue-masonry-wall";
import VueDatePicker from "@vuepic/vue-datepicker";
// import { CaptureConsole } from "@sentry/integrations";
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { plugin as VueInputAutowidth } from "vue-input-autowidth";
// import _refiner from "refiner-js";

// Services & Helpers
import { initToast } from "@/plugins/toast";
import { vFocus } from "./directives/focus";
import { projectAuth } from "./firebase/config";
import { initInlineSvg } from "@/plugins/inline-svg";
import { PtBr } from "./helpers/element-plus/lang-ptbr";

// Assets
import "nprogress/nprogress.css";
import "@vuepic/vue-datepicker/dist/main.css";
import "@/assets/sass/components/_toast.scss";

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
    fbq: (type: string, event: string) => void;
    clarity: ((set: string, key: string, value: string) => void) & ((event: string, value: string) => void);
  }
}

let app;
let head;

export const pinia = createPinia();

projectAuth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App);
    head = createHead();

    Sentry.init({
      app,
      environment: import.meta.env.VITE_NODE_ENV || "development",
      enabled: import.meta.env.VITE_NODE_ENV !== "development",
      trackComponents: true,
      dsn: "https://975f6ec67bd66350d0d5035edfd27968@o4504255628967936.ingest.sentry.io/4506659847995392",
      integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.01,
      replaysOnErrorSampleRate: 1.0,
      // Adicione esta configuração
      beforeSend(event) {
        if (event.level === "info") {
          return null; // Não envia eventos de nível 'info'
        }
        return event; // Envia outros níveis de evento
      }
    });

    app.use(pinia.use(piniaPluginPersistedstate));
    app.use(head);
    app.use(router);
    app.use(drag, {});
    app.use(VueRewards);
    app.use(MasonryWall);
    app.use(PrimeVue, {
      theme: {
        preset: Aura,
        options: {
          darkModeSelector: ".copybase-dark"
        }
      }
    });
    app.use(MotionPlugin);
    app.use(VueDragscroll);
    app.use(VueInputAutowidth);
    app.use(autoAnimatePlugin);
    app.use(ElementPlus, { locale: PtBr });
    app.use(Vue3Lottie, { name: "Vue3Lottie" });

    app.component("VueDatePicker", VueDatePicker);

    app.directive("focus", vFocus);

    initInlineSvg(app);
    initToast(app);

    mixpanel.init(`${import.meta.env.VITE_MIXPANEL_KEY}`, { cross_subdomain_cookie: true, debug: false });

    app.mount("#app");
  }
});
