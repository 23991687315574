// Core
import { createRouter, createWebHistory, NavigationGuard, RouteRecordRaw } from "vue-router";

// Libraries
import { pinia } from "@/main";
import NProgress from "nprogress";
import mixpanel from "mixpanel-browser";
import * as Sentry from "@sentry/vue";

// import { getInviteCount } from "@/utils/onboarding-storage";
// Composables
import { useUser, isUserTeamMember } from "@/composables/useUser";

// Stores
import { useUserStore } from "@/store/user";
import { useTeamStore } from "@/store/team";

const requireAuth: NavigationGuard = async (to, _from, next) => {
  const { updateStatus } = useUser();
  const userStore = useUserStore(pinia);

  const user = userStore.token;

  await updateStatus();

  if (userStore.userInfos == null) {
    userStore.token = userStore.userId = userStore.name = "";
  }

  if (window.dataLayer) {
    window.dataLayer.push({
      userId: userStore.userId,
      plan: {
        name: userStore.userInfos.role
      }
    });
  }

  if (!user) {
    next({ name: "login" });
  } else {
    // NOTE: Aqui estou pegando os dados de usuário diretamente do localStorage para que seja mais rápido de carregar.
    //       isso faz com que eu não precise fazer uma requição para adquirir os dados do usuário.
    const userInfos = userStore.userInfos;
    if (userInfos && window.dataLayer && to.fullPath !== "/sign-up" && to.fullPath !== "/login") {
      let planName;
      switch (userInfos.activeTeam?.teamRole) {
        case "basic":
          planName = "Básico";
          break;
        case "profissional":
          planName = "Profissional";
          break;
        case "ilimited":
          planName = "Escala";
          break;
        case "no-card":
        case "lead":
          planName = "Lead";
          break;
        case "canceled":
          planName = "Cancelado";
          break;
        case "failed":
          planName = "Falha na renovação";
          break;
        case "admin":
          planName = "Admin";
          break;
        default:
          planName = "Indefinido";
          break;
      }

      window.dataLayer.push({
        userId: userStore.userId,
        event: "virtualPageView",
        plan: {
          name: planName
        },
        occupation: userStore.$state.userInfos.onboarding?.occupationArea || "Indefinido",
        totalContentCount: userStore.$state.userInfos.onboarding?.totalContentCount,
        whereYouKnow: userStore.$state.userInfos.onboarding?.whereYouKnow || "Indefinido"
      });

      mixpanel.identify(userStore.userId); // or set multiple properties at once

      // register only for the current pageload
      mixpanel.register({
        $name: `${userStore.userInfos.firstName} ${userStore.userInfos.lastName}`,
        $email: userStore.userInfos.email,
        $phone: userStore.userInfos.phoneNumber
      });
      mixpanel.people.set({
        $name: `${userStore.userInfos.firstName} ${userStore.userInfos.lastName}`,
        $email: userStore.userInfos.email,
        $phone: userStore.userInfos.phoneNumber,
        "Time | ID": userStore.userInfos.activeTeam?.id,
        "Time | Nome": userStore.userInfos.activeTeam?.name,
        "Time | Permissão": userStore.userInfos.activeTeam?.role,
        "Time | Plano": userStore.userInfos.activeTeam?.teamRole,
        totalContentCount: userStore.userInfos.totalContentCount || 0,
        totalWordsGeneratedOverall: userStore.userInfos.totalWordsGeneratedOverall || 0,
        "Plano Freq.": userStore.userInfos.renewAt
          ? "Anual"
          : userStore.userInfos.role !== "lead"
            ? "Mensal"
            : "Indefinido",
        "Onboarding / Posição": userStore.userInfos.onboarding?.position || "Indefinido",
        "Onboarding / Segmento": userStore.userInfos.onboarding?.segment || "Indefinido",
        "Onboarding / Onde nos conheceu": userStore.userInfos.onboarding?.whereYouKnow || "Indefinido",
        "Onboarding / Plan to Use": userStore.userInfos.onboarding?.planToUse || "Indefinido"
      });

      if (window.fcWidget) {
        window.fcWidget.setExternalId(userStore.userId); // To set unique user id in your system when it is available
        window.fcWidget.user.setFirstName(userStore.userInfos.firstName);
        window.fcWidget.user.setEmail(userStore.userInfos.email);

        window.fcWidget.user.setProperties({
          firstName: userStore.userInfos.firstName,
          lastName: userStore.userInfos.lastName,
          email: userStore.userInfos.email,
          phone: userStore.userInfos.phoneNumber,
          plan: userStore.userInfos.role
        });
        window.fcWidget.setTags(["app", userStore.userInfos.role]);
      }

      // Habilita o Sentry apenas em produção
      if (import.meta.env.VITE_NODE_ENV !== "development")
        Sentry.setUser({ email: userStore.userInfos.email, id: userStore.userId });
    }

    next();
  }
};

const requireAdmin: NavigationGuard = async (_to, _from, next) => {
  const { userStatus } = useUser();

  if (userStatus.value?.role !== "admin" && userStatus.value?.activeTeam.teamRole !== "admin") {
    next({ name: "dashboard" });
  } else {
    next();
  }
};

const requirePlan: NavigationGuard = async (to, _from, next) => {
  const teamStore = useTeamStore(pinia);

  if (teamStore.teamIsLeadMachineOnly) {
    if ((to.name as string)?.includes("leads")) {
      next();
    } else {
      next({ name: "leads" });
    }
  } else {
    next();
  }
};

const requireOnboarding: NavigationGuard = async (_, __, next) => {
  const teamStore = useTeamStore(pinia);

  if (teamStore.teamIsLeadMachineOnly) return next();

  const { userStatus } = useUser();

  const completed = userStatus.value.onboarding.hasCompleted || userStatus.value.onboarding.hasCompletedStep2;

  if (completed) return next();

  const step = userStatus.value.onboarding.currentStep;

  if (step === 5) return next("projeto-onboarding");

  if (step === 4) return next("meetbase-onboarding");

  return next("sign-up");
};

const requireB2B: NavigationGuard = (to, _from, next) => {
  const { isB2B } = useUser();

  if (isB2B.value) {
    next();
  } else {
    if (to.name === "folders-list-favorite") {
      next("/favoritas");
      return;
    } else if (to.name === "folders-list") {
      next("/historico");
      return;
    }
    next("/dashboard");
  }
};

const requireTeamAdmin: NavigationGuard = (_to, _from, next) => {
  if (!isUserTeamMember.value) {
    next();
  } else {
    next("/dashboard");
  }
};

const requireMeetbase: NavigationGuard = (_to, from, next) => {
  const teamStore = useTeamStore(pinia);

  if (teamStore.teamHasMeetbase) {
    next();
  } else {
    next(from);
  }
};

const requireLeadMachine: NavigationGuard = (_to, from, next) => {
  const teamStore = useTeamStore(pinia);

  if (teamStore.teamHasLeadMachine) {
    next();
  } else {
    next(from);
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("@/components/layout/LayoutComponent.vue"),
    beforeEnter: [requireAuth, requirePlan, requireOnboarding],
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        beforeEnter: [requirePlan],
        component: () => import("@/views/HomeView.vue")
      },
      {
        path: "/gerador/chat/:id?",
        name: "chat",
        props: true,
        beforeEnter: [requirePlan],
        component: () => import("@/views/ChatView.vue")
      },
      {
        path: "/gerador-v2/:id",
        name: "gennai2",
        props: true,
        beforeEnter: [requirePlan],
        component: () => import("@/views/GennaiView.vue")
      },
      {
        path: "/modelos",
        name: "modelos",
        beforeEnter: [requirePlan],
        component: () => import("@/views/ModelsView.vue")
      },
      {
        path: "/projeto/",
        name: "create-project",
        beforeEnter: [requirePlan],
        component: () => import("@/views/project/ProjectCreation.vue")
      },
      {
        path: "/planos/",
        name: "Plans",
        beforeEnter: [requireTeamAdmin],
        component: () => import("@/views/PricingTable.vue")
      },
      {
        path: "/conta",
        name: "account",
        component: () => import("@/views/account/AccountView.vue"),
        children: [
          {
            path: "config",
            name: "account-settings",
            component: () => import("@/views/account/SettingsView.vue")
          }
        ]
      },
      {
        path: "/documentos/",
        name: "Documentos",
        beforeEnter: [requirePlan],
        component: () => import("@/views/docbase/DocumentsList.vue")
      },
      {
        path: "/transcricoes/",
        name: "transcriptions",
        beforeEnter: [requirePlan],
        component: () => import("@/views/transcription/TranscriptionsList.vue")
      },
      {
        path: "/videos/:vslId/edit/:documentId",
        name: "videos",
        beforeEnter: [requirePlan],
        component: () => import("@/views/videos/EditTextVSL.vue")
      },
      {
        path: "/escritor/:id?/:chatId?",
        name: "escritor-no-doc",
        props: true,
        beforeEnter: [requirePlan],
        component: () => import("@/views/docbase/DocbaseView.vue")
      },
      {
        path: "/gerador/vsl/",
        name: "gerador-vsl",
        beforeEnter: [requirePlan],
        component: () => import("@/views/videos/NewVideo.vue")
      },
      {
        path: "/gerador/artigo/",
        name: "gerador-artigo",
        props: true,
        beforeEnter: [requirePlan],
        component: () => import("@/views/docbase/NewDocument.vue")
      },
      {
        path: "/gerador/imagem/",
        name: "gerador-imagem",
        beforeEnter: [requirePlan],
        component: () => import("@/views/images/ImagesGeneratorView.vue")
      },
      // {
      //   path: "/editor/imagem/:url?",
      //   name: "editor-imagem",
      //   props: true,
      //   beforeEnter: [requirePlan],
      //   component: () => import("@/views/images/ImageEditor.vue")
      // },
      {
        path: "/gerador/transcrever-video",
        name: "transcrever-video",
        props: true,
        beforeEnter: [requirePlan],
        component: () => import("@/views/transcription/VideoTranscriber.vue")
      },
      {
        path: "/atualizar-projeto/",
        name: "update-project",
        beforeEnter: [requireB2B, requirePlan],
        component: () => import("@/views/project/UpdateProject.vue")
      },
      {
        path: "/a63f438ec472b454321925691dff72b7",
        name: "admin",
        beforeEnter: [requireAdmin],
        component: () => import("@/views/admin/AdminView.vue")
      },

      {
        path: "/admin/partner",
        name: "partner",
        beforeEnter: [requireAdmin, requirePlan],
        component: () => import("@/views/admin/PartnerView.vue")
      },
      {
        path: "/404",
        name: "404",
        component: () => import("@/views/error/NotFoundView.vue")
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/error/ServerErrorView.vue")
      },
      {
        path: "/:pathMatch(.*)*",
        redirect: "/404"
      },
      {
        path: "/equipe/:id",
        name: "team",
        beforeEnter: [requireB2B, requirePlan],
        component: () => import("@/views/team/TeamView.vue")
      },
      {
        path: "/equipe/:id/projetos/",
        name: "project-list",
        beforeEnter: [requireB2B, requirePlan],
        component: () => import("@/views/project/ProjectList.vue")
      },
      {
        path: "/equipe/:id/projetos/historico",
        name: "folders-list",
        beforeEnter: [requirePlan],
        component: () => import("@/views/project/FoldersView.vue")
      },
      {
        path: "/equipe/:id/projetos/favoritos",
        name: "folders-list-favorite",
        beforeEnter: [requireB2B, requirePlan],
        component: () => import("@/views/project/FoldersView.vue")
      },
      {
        path: "/universidade",
        name: "universidade",
        beforeEnter: [requirePlan],
        component: () => import("@/views/university/UniversityView.vue")
      },
      {
        path: "/universidade/:category",
        name: "universidade-category",
        beforeEnter: [requirePlan],
        component: () => import("@/views/university/UniversityCategory.vue")
      },
      {
        path: "/universidade/:category/:video",
        name: "universidade-video",
        beforeEnter: [requirePlan],
        component: () => import("@/views/university/UniversityVideo.vue")
      },
      {
        path: "/transcrever-call",
        name: "transcrever-call",
        beforeEnter: [requireMeetbase],
        component: () => import("@/views/meetbase/SubmitFile.vue")
      },
      {
        path: "/gravacoes",
        name: "calls-list",
        component: () => import("@/views/meetbase/CallsList.vue")
      },
      {
        path: "/resultado-gravacao/:id",
        name: "call-result",
        beforeEnter: [requireMeetbase],
        component: () => import("@/views/meetbase/CallResult.vue")
      },
      {
        path: "/maquina-de-leads",
        name: "leads-machine",
        beforeEnter: [requireLeadMachine],
        component: () => import("@/views/leads/LeadsRobotView.vue")
      },
      {
        path: "/listas-de-leads",
        name: "leads",
        beforeEnter: [requireLeadMachine],
        component: () => import("@/views/leads/LeadsListing.vue")
      },
      {
        path: "/listas-de-leads/:listId/:leadId?",
        name: "leads-list",
        beforeEnter: [requireLeadMachine],
        component: () => import("@/views/leads/LeadsList.vue")
      },
      {
        path: "/automacoes",
        name: "automations",
        beforeEnter: [requireLeadMachine],
        component: () => import("@/views/leads/AutomationsListing.vue")
      },
      {
        path: "/automacoes-historico",
        name: "automations-history",
        beforeEnter: [requireLeadMachine],
        component: () => import("@/views/leads/AutomationsHistory.vue")
      },
      {
        path: "/vendedores",
        name: "sellers",
        beforeEnter: [requireLeadMachine],
        component: () => import("@/views/leads/SellersListing.vue")
      },
      {
        path: "/integracoes",
        name: "integrations",
        component: () => import("@/views/integrations/IntegrationsListing.vue")
      }
    ]
  },
  {
    path: "/",
    component: () => import("@/views/auth/AuthView.vue"),
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("@/views/auth/LoginView.vue")
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () => import("@/views/auth/PasswordReset.vue")
      }
    ]
  },
  {
    path: "/onboarding",
    name: "onboarding",
    beforeEnter: [requireAuth],
    component: () => import("@/views/auth/MultiStepOnboarding.vue")
  },
  {
    path: "/equipe/convite",
    name: "team-invite",
    component: () => import("@/views/team/TeamInvite.vue")
  },
  {
    path: "/convidado-onboarding",
    name: "onboarding-convidado",
    beforeEnter: [requireAuth],
    component: () => import("@/views/GennaiOnBoard.vue")
  },
  {
    path: "/onboarding-equipe",
    name: "create-team",
    beforeEnter: [requireAuth],
    component: () => import("@/views/team/CreateTeam.vue")
  },
  {
    path: "/sign-up",
    name: "onboarding-cadastro",
    component: () => import("@/views/auth/SignUpOnboarding.vue")
  },
  {
    path: "/projeto-onboarding",
    name: "onboarding-projeto",
    beforeEnter: [requireAuth],
    component: () => import("@/views/project/ProjectOnboarding.vue")
  },
  {
    path: "/meetbase-onboarding",
    name: "meetbase-onboarding",
    beforeEnter: [requireAuth],
    component: () => import("@/views/meetbase/AddMeetbase.vue")
  },
  {
    path: "/trial-iniciado",
    name: "trial-iniciado",
    beforeEnter: [requireAuth],
    component: () => import("@/views/project/TrialStarted.vue")
  },
  {
    path: "/resultado-gravacao-publica/:id",
    name: "public-call-result",
    component: () => import("@/views/meetbase/PublicView.vue")
  },
  {
    path: "/",
    beforeEnter: [requireAuth],
    component: () => import("@/views/auth/AuthView.vue"),
    children: [
      {
        path: "/google-auth",
        name: "google-auth",
        component: () => import("@/views/auth/GoogleAuth.vue")
      },
      {
        path: "/instrucoes-meetbase",
        name: "meetbase-instructions",
        component: () => import("@/views/meetbase/MeetbaseHelp.vue")
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

router.beforeEach(() => {
  NProgress.start();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
